import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { REQUEST_SAUNA_RESET, SEND_REQUEST } from "../../sagas/actions";
import accessResetApiCall from "../../modules/api/accessResetApiCall";
import MessageBox from "./MessageBox";

const SaunaNotWorkingModal = ({ modalStatus, setSaunaModal, reduxState, requestSaunaReset, message }) => {
  const handleReset = () => {
    console.log("SaunaNotWorkingModal accessKey:", reduxState.access.data.accessKey);
    requestSaunaReset(reduxState.access.data.accessKey);
  };

  return (
    <Dialog open={modalStatus} onClose={() => setSaunaModal(false)}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setSaunaModal(false)}
          sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          If you are experiencing issues with the sauna, you can try rebooting it by clicking this button.
        </Typography>
        <Box mt={2} mb={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ minWidth: "150px", minHeight: "50px", zIndex: 0 }}
            onClick={handleReset}
          >
            REBOOT SAUNA
          </Button>
        </Box>
        {message && <MessageBox message={message} />}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (reduxState) => ({
  reduxState,
  message: reduxState?.saunaControlsMessageBox?.message || null,
});

const mapDispatchToProps = (dispatch) => ({
  requestSaunaReset: (accessKey) => {
    dispatch({
      type: SEND_REQUEST,
      loadActionType: REQUEST_SAUNA_RESET,
      apiCall: accessResetApiCall,
      apiCallParams: { accessKey },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SaunaNotWorkingModal);
