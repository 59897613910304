import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import RedLight from "./RedLight";
import Uvb from "./Uvb";
import Access from "./Access";
import AccessNavBar from "../Nav/AccessNavBar";
import TowelService from "./TowelService";

export class AccessContent extends Component {
  state = {
    contentLayout: null,
    addonCount: 0,
    firstBC: "#880f12",
    secondBC: "#234b96",
    thirdBC: "#7d7d7d",
    backgroundColor: "#353d3b",
    backgroundImage: null,
    navColorPrimary: "#353d3b",
  };
  listenScrollEvent = async () => {
    const windowH = window.innerHeight;
    const contentWrapper = document.querySelector("#ContentWrapper");
    const scrollPos = contentWrapper.scrollTop;
    // const scrollPos = window.scrollY;
    const oneThirdWindowH = windowH / 3;
    const oneThirdPlusOne = oneThirdWindowH + windowH;
    const oneThirdPlusTwo = oneThirdWindowH + windowH * 2;
    const oneThirdPlusThree = oneThirdWindowH + windowH * 3;
    // const firstImage = this.state.firstImage;
    // const secondImage = this.state.secondImage;
    // const thirdImage = this.state.thirdImage;
    if (scrollPos < oneThirdWindowH) {
      this.setState({
        backgroundColor: "#080a09",
        navColorPrimary: "#353d3b",
      });
    } else if (oneThirdWindowH < scrollPos && scrollPos < oneThirdPlusOne) {
      this.setState({
        backgroundColor: this.state.firstBC,
        navColorPrimary: "#242424",
      });
    } else if (oneThirdPlusOne < scrollPos && scrollPos < oneThirdPlusTwo) {
      this.setState({
        backgroundColor: this.state.secondBC,
        navColorPrimary: "#242424",
      });
    } else if (oneThirdPlusTwo < scrollPos && scrollPos < oneThirdPlusThree) {
      this.setState({
        backgroundColor: this.state.thirdBC,
        navColorPrimary: "#242424",
        // navColorPrimary: "#242424",
      });
    }
  };

  componentDidMount() {
    this.mergeSectionsAndUpdate();
    this.assignPictures();
    const ContentWrapper = document.getElementById("ContentWrapper");
    ContentWrapper.addEventListener("scroll", this.listenScrollEvent);
    // this.accessStyles();
  }

  // accessStyles = () => {
  //   const boxHeight = (window.innerHeight * 2) / 3;
  //   const navBarHeight = document.getElementById("navBar");
  //   console.log("navBarHeight: ", navBarHeight);
  //   return {
  //     height: boxHeight < 800 ? boxHeight : 800,
  //     // height: window.innerHeight,
  //     // height: element.innerHeight,
  //   };
  // };
  convertToBackGroundColor = (type) => {
    if (type === "red_light") {
      return "#880f12";
    } else if (type === "uvb") {
      return "#333362";
    } else if (type === "towel_service") {
      return "#7d7d7d";
    }
  };

  assignPictures = () => {
    const appliances = this.props.appliances;
    appliances.map((appliance, i) => {
      const color = this.convertToBackGroundColor(appliance.appliance_type);
      if (i === 0) {
        this.setState({
          firstBC: color,
        });
      } else if (i === 1) {
        this.setState({
          secondBC: color,
        });
      } else if (i === 2) {
        this.setState({
          thirdBC: color,
        });
      }
      return null;
    });
  };

  mergeSectionsAndUpdate = () => {
    //to dynamically add appliances based on data received
    const redLightSection = this.createSection("red_light", "redLight", <RedLight />);
    const uvbSection = this.createSection("uvb", "uvb", <Uvb />);
    const towelSection = this.createSection("towel_service", "towelService", <TowelService />);
    const layout = (
      <>
        {redLightSection}
        {uvbSection}
        {towelSection}
      </>
    );
    this.setState({
      ...this.state,
      contentLayout: layout,
    });
  };

  createSection = (type, sectionId, section) => {
    const appliances = this.props.appliances;
    return appliances.map((appliance) => {
      if (appliance.appliance_type === type) {
        return (
          <section key={sectionId} id={sectionId} className="AccessCell">
            {section}
          </section>
        );
      }
      return null;
    });
  };

  render() {
    return (
      <div className="AppWrapper">
        <AccessNavBar id="navBar" navColorPrimary={this.state.navColorPrimary} />
        <div className="bgColor" style={{ backgroundColor: this.state.backgroundColor, zIndex: 2 }}>
          <main id="ContentWrapper" style={{ zIndex: 1, scrollSnapType: "y mandatory" }}>
            <div id="access" className="AccessCell">
              <Access />
            </div>
            {this.state.contentLayout}
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  appliances: reduxState.access.data.appliances,
});

export default connect(mapStateToProps)(AccessContent);
