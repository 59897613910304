import React, { Component } from "react";
import "./AccessContent.css";
import "./AccessButton.css";

import { connect } from "react-redux";
// import { Button } from "react-bootstrap";
import { REQUEST_ADD_RED_LIGHT, SEND_REQUEST } from "../../sagas/actions";
import addRedLightApiCall from "../../modules/api/addRedLightApiCall";
import RedLightButtonStatusIconManager from "./RedLightButtonStatusIconManager";
import MessageBox from "./MessageBox";

export class AddRedLightView extends Component {
  render() {
    return (
      <div className="AddRedLightViewWrapper">
        <h2 className="CardBodyText">Want to add Red Light to your appointment?</h2>
        <h3 className="CardBodyText">A $10 charge will be added to your account.</h3>
        <div className="ButtonWrapper">
          <button
            className={
              !this.props.reduxState.redLightMessageBox.buttonDisabled ? "access-button" : "btn btn btn-primary-outline"
            }
            type="button"
            size="medium"
            // className="AccessButtons"
            style={{ zIndex: 0, minWidth: "150px", minHeight: "50px" }}
            disabled={this.props.reduxState.redLightMessageBox.buttonDisabled}
            id="addRedLightButton"
            onClick={() => {
              this.props.addRedLight(this.props.reduxState.access.data.accessKey);
            }}>
            ADD RED LIGHT
          </button>
          <RedLightButtonStatusIconManager className="ButtonStatusIcon" id="addRedLightIconStaus" />
        </div>
        {this.props.reduxState.redLightMessageBox.message ? (
          <MessageBox message={this.props.reduxState.redLightMessageBox.message} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addRedLight: (accessKey) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: REQUEST_ADD_RED_LIGHT,
        apiCall: addRedLightApiCall,
        apiCallParams: { accessKey: accessKey },
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRedLightView);
