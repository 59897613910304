import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Tooltip, Box, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const AccessNotWorkingModal = ({ modalStatus, setAccessModal, reduxState }) => {
  const accessKey = reduxState.access.data.accessKey;

  return (
    <Dialog open={modalStatus} onClose={() => setAccessModal(false)}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Access Issue?</Typography>
          <IconButton onClick={() => setAccessModal(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} alignItems="center">
          <Typography width="100%" textAlign="left">If you cannot gain access you can text this code</Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" sx={{ mr: 1 }}>
              {accessKey}
            </Typography>
            <CopyToClipboard text={accessKey}>
              <Tooltip title="Copy Code">
                <IconButton>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Box>
          <Typography textAlign="left">Text <a href="tel:6124456278" style={{ color: '#1976d2' }}>612-445-6278</a> as an alternate method to unlock the door.</Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            This will not work if access is expired or if it has not started yet.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (reduxState) => ({ reduxState });

export default connect(mapStateToProps)(AccessNotWorkingModal);
