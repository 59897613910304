import accessDataApiCall from "../../modules/api/accessDataApiCall";
import React, { useEffect } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import AccessContent from "./AccessContent";
import AccessContentLoading from "./AccessContentLoading";
import { SEND_REQUEST, REQUEST_USER_ACCESS_DATA } from "../../sagas/actions";
import AccessContentFailed from "./AccessContentFailed";
import { useParams } from "react-router-dom";
import { AfterHourInstructions } from "./AfterHourInstructions";

const AccessContentManager = (props) => {
  const { key } = useParams();
  const { requestUserData } = props;
  useEffect(() => {
    requestUserData(key);
  }, [requestUserData, key]);
  const [clientReadIntructions, setClientReadInstructions] = React.useState(false);

  const displayComponent = (appointment) => {
    if (props.credentialValid) {
      if (clientReadIntructions) {
        return <AccessContent />;
      } else {
        console.log('appointment: ', appointment);
        return <AfterHourInstructions appointment={appointment} setClientReadInstructions={setClientReadInstructions}/>;
      }
    } else if (props.credentialValid === false) {
      return <AccessContentFailed />;
    } else if (props.credentialValid === null) {
      return <AccessContentLoading />;
    }
  };
  return <div className="ContentManagerWrapper">{displayComponent(props.appointment)}</div>;
};

const mapStateToProps = (reduxState) => {
  return {
    credentialValid: reduxState.access.credentialValid,
    appointment: reduxState.access.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestUserData: (accessKey) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: REQUEST_USER_ACCESS_DATA,
        apiCall: accessDataApiCall,
        apiCallParams: { accessKey: accessKey, entry: null },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessContentManager);