import React, { Component } from "react";
import { Paper, Typography, Box, List, ListItem, ListItemText, Button } from "@mui/material";
import moment from "moment-timezone";
import AccessNavBar from "../Nav/AccessNavBar";

export class AfterHourInstructions extends Component {
  state = {};

  renderServiceName = (appointment) => {
    console.log("appointment: ", appointment);
    if (appointment.appointment.infraredSauna) {
      return "Infrared Sauna";
    } else if (appointment.appointment.salt) {
      return "Salt";
    }
    return "";
  };

  supportLine = (siteId) => {
    if (siteId === 43256) {
      //Awaken for wellness - Stpaul, woodbury, hudson
      return "If you are experiencing any issues please call Chris at 651-329-1385";
    } else if (siteId === 5729187) {
      //NE Wellness - Bismarck
      return "If you are experiencing any issues please call +1(701)-955-5974 or Luz at 701-319-0402";
    } else if (siteId === 544539) {
      //NE Wellness - Minneapolis
      return "If you are experiencing any issues please call 612-466-0518.";
    } else {
      return "If you are experiencing any issues please call Matt from Technical Support at 651-587-8218.";
      // return "If you are experiencing any issues please call Matt from Technical Support at 651-587-8218 or Jesse at 651-366-0283";
    }
  };
  bulletLines = (appointment) => {
    if (appointment.infraredSauna) {
      return (
        <>
          <ListItem>
            <ListItemText primary="• Bring towels & a bottle of water." />
          </ListItem>
        </>
      );
    } else if (appointment.salt) {
      return (
        <>
          <ListItem>
            <ListItemText primary="• Sign in on the sheet at the desk indicating how many guests & any purchases" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Find your room and find the correlating salt machine." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Fill the salt in the salt machine and push start!" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  • For an instructional video please click this link:{" "}
                  <a href="https://youtu.be/YO4eK2t1Z7g" target="_blank" rel="noopener noreferrer">
                    https://youtu.be/YO4eK2t1Z7g
                  </a>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="" />
          </ListItem>
        </>
      );
    }
  };

  cleaningLine = (appointment) => {
    console.log("cleaningLine appointment: ", appointment);
    if (appointment?.infraredSauna) {
      if (appointment?.siteId === 5729187) {
        return (
            "Please follow the cleaning guidelines posted in the sauna room. Failure to do so will result in revoking your after-hours booking privileges."
        );
      } else if (appointment?.siteId === 43256) {
        return (
            "Please leave the room, sauna door, and sauna floor as clean as you found it with the provided cleaning supplies. Additional towels and floor mats are available when you sign in. Failure to leave a clean room for the next guest may result in a charge."
        );
      } else {
        console.log("returning default cleaning line");
        return (
            "Clean off the touch panel and handles. Keep the glass clear of sweat, or use the glass cleaner if touched."
        );
      }
    } else if (appointment?.salt) {
      return (
          "Please leave the room as clean as you found it."
      );
    }
  };

  render() {
    const { appointment, setClientReadInstructions } = this.props;
    console.log("AfterHourInstructions appointment: ", appointment);

    return (
      <>
        <AccessNavBar id="navBar" navColorPrimary={"#353d3b"} />
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column", // Aligns items vertically
            justifyContent: "flex-start", // Aligns content to the top
            alignItems: "center",
            padding: 2,
            paddingTop: 8, // Ensures no overlap with the navbar
            backgroundColor: "#353d3b",
            overflow: "auto", // Adds scrolling if content overflows
          }}
        >
          {appointment && (
            <Paper
              elevation={3}
              sx={{
                padding: 4,
                maxWidth: 600,
                width: "100%", // Make sure it doesn't exceed the container
                backgroundColor: "#ffffff", // White paper background
                color: "#000000", // Ensure text color is black
              }}
            >
              <Typography variant="body1" gutterBottom>
                You have an UNSTAFFED {this.renderServiceName(appointment)} Session at{" "}
                <strong>{moment(appointment?.appointment?.startDateTime).format("h:mm a")}</strong> on{" "}
                <strong>{moment(appointment.startDateTime).format("dddd (MM/DD)")}</strong> in
                <br />
                <strong>{appointment.room}</strong>.
              </Typography>

              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        • Door access will be valid from{" "}
                        <strong style={{ textWrap: "nowrap" }}>
                          {" "}
                          {moment(appointment?.startDateTime).format("h:mm a")}{" "}
                        </strong>
                        to{" "}
                        <strong style={{ textWrap: "nowrap" }}>
                          {moment(appointment?.endDateTime).format("h:mm a")}
                        </strong>
                        .
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="• No shows are a $5 charge." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="• Late shows will not have entry outside of the access window." />
                </ListItem>
                {this.bulletLines(appointment?.appointment)}
              </List>
              <Typography variant="body2" color="textSecondary" gutterBottom textAlign="left">
                {this.supportLine(appointment.appointment.siteId)}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom textAlign="left">
                Please do not contact Customer Care prior to 7AM or after 10PM unless there is an emergency, or your
                door code doesn't work.
              </Typography>
              <br />
              <Typography variant="body2" color="textSecondary" gutterBottom textAlign="left">
                <strong>NOTE:</strong> This appointment will be unstaffed. {this.cleaningLine(appointment?.appointment)}{" "}
                Thank you!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 4,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#277165",
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    setClientReadInstructions(true);
                  }}
                >
                  Proceed to Access Pass
                </Button>
              </Box>
            </Paper>
          )}
        </Box>
      </>
    );
  }
}

export default AfterHourInstructions;
